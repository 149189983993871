@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;500&display=swap');
@font-face {
    font-family: 'Comic Sans MS';
    font-style: normal;
    font-weight: 400;
    src: local('Comic Sans MS'), local('ComicSansMS-Regular'),
        url(https://allfont.ru/cache/fonts/comic-sans-ms_53a8778a817839ef2dbc5dc94ce14334.woff) format('woff'),
        url(https://allfont.ru/cache/fonts/comic-sans-ms_53a8778a817839ef2dbc5dc94ce14334.ttf) format('truetype');
}
.navbar.bg-dark{
    background-color: black !important;
    border-color:black !important;
}
p,a,span,div{
    font-family: 'Comic Sans MS','Comic Sans', cursive;
}
.navbar-dark .navbar-brand{
    color:#F97829;
}
.navbar-nav,.social-connect{
    background-color:black;
}
.show .navbar-nav, .collapsing .navbar-nav{
    padding:2rem;
}
.navbar-dark .navbar-nav .nav-link{
    color:white;
}
.navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .nav-link:hover{
    color:#F76B26;
}
h1,h2,h3{
    font-family: 'Comic Sans MS','Comic Sans', cursive;
    font-weight:900;
    letter-spacing:1px;
    color:#FDCA3D;
}
h4,h5,h6{
    color:white;
    font-family: 'Comic Sans MS','Comic Sans', cursive;
    font-weight:900;
    letter-spacing: 0.3px;
}
#root{
    overflow-x:hidden;
}
.wallet-adapter-button.custom_btn{
    background-image: linear-gradient(#FDB638, #F97829);
    color:#1C1DE2;
    white-space: nowrap;
}
.wallet-adapter-button.custom_btn:hover, .custom_btn:hover,.mint-btn:hover,.btn-discord:hover{
    color:#FDB638;
    background-image: none;
    background-color:#F97829;
}
#why-and-how{
    background-image: linear-gradient(#F97829,#FDB638);
}
.whyscreen ul{
    color: #D3AA67;
    padding: 5px;
    margin-top:112px;
}
.whyscreen ul > li{
    margin-top: 12px;
}
.whoscreen,#homescreen,#howmanyscreen,
.whyscreen,#teamscreen,#faqscreen,
#roadmapscreen1,#roadmapscreen2,.communityscreen
{
    width:100%;
    min-height:100vh;
    padding-bottom:100px;
}

#roadmapscreen1 h2{
    color:#191DEA;
}
.whoscreen,.communityscreen{
    background-color:#FFC938;
    position: relative;
    padding-bottom:150px;
    padding-top:150px;
}
.MuiTypography-root{
    color: white !important;
}
.whoscreen h1,.whoscreen p{
    font-family: 'Roboto', sans-serif;
    background:linear-gradient(to right,#FF7D26, #FFCA01);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}
.communityscreen .center{
    background:linear-gradient(to right,#FF7D26, #FFCA01);
    width:80vw;
}
.communityscreen h1{
   color:#191DEA;
    
}
.whoscreen h1, .communityscreen h1{
    font-weight:900;
}
.whoscreen p{
    text-align: center;
    color:#F76B26;
    font-size:medium;
    font-weight:500;
}
.whocard-container{
    text-align: center;
    flex-direction: row;   /* align children in rows */
    flex-wrap: wrap;       /* allow wrapping of children for multiple rows */
    width: 80%;            /* change to width you want */
    margin: auto;
}
.slick-slider{
    display:flex;          /* make children flex too for centering */
    align-items:center;    /* vertical center */
    justify-content:center; /* horizontal center */
    
}
.modal.shit-bg .modal-body,.modal.shit-bg .modal-content,.modal.shit-bg .modal-header{
    background-color: transparent;
    background-position: center;
    background-size: contain;
    border:none;
}
.modal.shit-bg .modal-header {
    background-repeat: repeat-x;
    background-position: center;
    color:#F7CC09;
    text-align: center;
}
.modal.shit-bg .modal-body{
    background-size: cover;
    color:white;
    font-size:1.25rem;
    padding:1.75rem;
}
.modal.shit-bg .modal-header .modal-title{
   margin:0 auto;

}
.modal.shit-bg .modal-header .btn-close{
    color:#573403;
    background-color: #EB6D0A;
    border-radius: 50%!important;
    opacity: 1;
}
.modal.shit-bg .modal-body{
    background-repeat: no-repeat;

}

.whocard{
    background-color:#00132A;
    box-shadow: 10px 15px #000C2A;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width:80vw;
}
#teamscreen,#faqscreen{
    background-color: #3D54E0;
}
#teamscreen,.whyscreen,.whoscreen,#howmanyscreen,#faqscreen{
    padding-top:100px;
}
#howmanyscreen h2{
    color:#EB6D0A;
}
.wallet-adapter-dropdown > button, .wallet-adapter-button{
    height:35px;
    font-family: 'Comic Sans MS','Comic Sans', cursive;
}
button.MuiButton-root{
    font-family: 'Comic Sans MS','Comic Sans', cursive !important;
    color:white !important;
}
#homescreen{
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
#homescreen .center,.communityscreen .center{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width:80vw;

}
#homescreen .center h1{
    color:#F7CC09;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.8);;
}
.why-become-list{
    font-size:1rem;
}
.mint-btn, .btn-discord{
    background-image: linear-gradient(#FDB638, #F97829);
    color:#1C1DE2;
    letter-spacing: 1px;
    text-transform:none;
    padding: 3px 20px;
    border:#FFC938 1px;
    box-shadow: 2px 4px #1C1DE2;
}
.shit-modal.modal{
    background-color: #171002;
    opacity:1;
}
.shit-modal .modal-open{
    height: 100vh;
}
.shit-modal .modal-content{
    background:transparent;
    border:none;
}
.shit-modal .modal-header{
    border-bottom:none;
}
.shit-modal .btn-close{
    background-color: #F76B26;
    border-radius: 50%!important;
}
.accordion-button {
    background-image: linear-gradient(#F87D2A, #F97829);
    color:#191DEA;
}
.accordion-item:last-of-type .accordion-button.collapsed, 
.accordion-item:first-of-type .accordion-button,
.accordion-item:last-of-type,
.accordion-item:last-of-type
{
    border-radius: 0.50rem;
}
.accordion-item{
    border:none;
    border-radius: 0.50rem;
    background-color: #F7CC09;
}
.accordion-header {
    border-radius: 0.50rem;
}
.accordion-header button{
    font-size: 1.25rem;
    font-weight:900;
}
.accordion-button:not(.collapsed)::after,.accordion-button::after{
    background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3C%21--%21%20Font%20Awesome%20Pro%206.1.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%20%28Commercial%20License%29%20Copyright%202022%20Fonticons%2C%20Inc.%20--%3E%3Cpath%20d%3D%22M512%20440.1C512%20479.9%20479.7%20512%20439.1%20512H71.92C32.17%20512%200%20479.8%200%20440c0-35.88%2026.19-65.35%2060.56-70.85C43.31%20356%2032%20335.4%2032%20312C32%20272.2%2064.25%20240%20104%20240h13.99C104.5%20228.2%2096%20211.2%2096%20192c0-35.38%2028.56-64%2063.94-64h16C220.1%20128%20256%2092.12%20256%2048c0-17.38-5.784-33.35-15.16-46.47C245.8%20.7754%20250.9%200%20256%200c53%200%2096%2043%2096%2096c0%2011.25-2.288%2022-5.913%2032h5.879C387.3%20128%20416%20156.6%20416%20192c0%2019.25-8.59%2036.25-22.09%2048H408C447.8%20240%20480%20272.2%20480%20312c0%2023.38-11.38%2044.01-28.63%2057.14C485.7%20374.6%20512%20404.3%20512%20440.1z%22%2F%3E%3C%2Fsvg%3E);
    color: #5C3814;
}
.card-how-many{
    cursor:pointer;
    background:radial-gradient(#F7CC09,#F87D2A);
    border-width:15px;
    border-color:#3D54E0;
    overflow:visible;
    width:90%;
    height:70%;
    box-shadow: 3px 7px rgba(0, 0, 0, 0.4);
    margin:auto;
}
.card-how-many .card-img, .card-how-many .card-img-bottom{
    position:relative;
    top: -35px;
}
.card-how-many .card-title{
    color:#1C1DE2;
    position:relative;
    top:-35px;
}
.slick-list{
    padding-top:35px;
    padding-bottom:5px;
}
.card-type{
    position:absolute;
    padding: 3px 10px;
    background-color:#F7CC09;
    border-color:#F76B26;
    border-style: solid;
    color:#191DEA;
    border-width:2px;
    box-shadow: 3px 7px #000;
    bottom:0;
    right:0;
    -ms-transform: skewX(-20deg); /* IE 9 */
    -webkit-transform: skewX(-20deg); /* Safari */
    transform: skewX(-20deg);
}
.card-type.legendary{
    background-color:#191DEA;
    color:#F7CC09;
}
.card-type.rare{
    background-color:#F76B26;
    color:#191DEA;
    border-color:#F7CC09;
}
.card-type.common{
    background-color:#573403;
    color:#F7CC09;
    border-color:#F76B26;
}
span.card-type > div{
    -ms-transform: skewX(20deg); /* IE 9 */
    -webkit-transform: skewX(20deg); /* Safari */
    transform: skewX(20deg);
}
.card-type.card-back-name{
    position:relative;
    left: 0; 
    top:0;
    width:80%;
    color:#191DEA;
    white-space: nowrap;
    z-index:2;
}
.card-type.card-back-type{
    position:relative;
    right: 0; 
    left:25%;
    top:-.77rem;
    width:80%;
    color:#191DEA;
}
.card-back-how-many {
    background-color: transparent;
    background-size: 210%;
    background-position:center center;
    color:white;
}
.docs-card{
    cursor:pointer;
    background:linear-gradient(#F7CC09,#F87D2A);
    border-width:15px;
    border-color:#231A0E;
    overflow:visible;
    box-shadow: 3px 7px rgba(0, 0, 0, 0.4);
    margin:auto;
}
.docs-title{
    padding: 3px 15px;
    background-color: #F76B26;
    position:absolute;
    top:-5px;
    border:solid 2px #FFC938;
    -ms-transform: skewX(-20deg); /* IE 9 */
    -webkit-transform: skewX(-20deg); /* Safari */
    transform: skewX(-20deg);
    box-shadow: 3px 7px #000;
}
.docs-title > div, .docs-type > div{
    -ms-transform: skewX(20deg); /* IE 9 */
    -webkit-transform: skewX(20deg); /* Safari */
    transform: skewX(20deg);
}
.docs-type{
    padding: 3px 15px;
    background-color:#191DEA;
    position:absolute;
    bottom:0;
    right:0;
    color:#FFC938;
    border: solid 2px #FFC938;
    -ms-transform: skewX(-20deg); /* IE 9 */
    -webkit-transform: skewX(-20deg); /* Safari */
    transform: skewX(-20deg);
    box-shadow: 3px 7px #000;
}
#roadmap{
    overflow:hidden;
}
#roadmap .poop{
    color:#F87D2A;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
}
#roadmap .poop .h6{
    color: white;
}
#roadmap .poop.poop-light{
    color:#6C3C17;
}
#roadmap .poop.poop-light .h6{
    color:#000;
}
#roadmap .poop-container{
    background-repeat: no-repeat;
    background-size: contain;
    position:relative !important;
    height:34vh;
    background-position: right center;
}
.to-top{
    position:fixed;
    bottom:5px;
    right:5px;
    width:3rem;
    height:3rem;
    z-index:999;
    cursor: pointer;
}
.team-bar{
    height:25vh;
    background:linear-gradient(#F7CC09,#F87D2A);
    position:relative;
    top:50vh;
}
.dropdown .nft-menu{
    position:absolute;
    left: 5px;
    top: 5px;
    background-color:#000;
    border-color: rgba(0, 0, 0, 0.4);
    color: white;
}
.dropdown .show .fa-ellipsis{
    color:black !important;
}
.dropdown .nft-dropdown-popup{
    z-index:99;
}
.nft-views{
    position:absolute;
    top:5px;
    right:5px;
}
@media only screen and (max-width:1000px){
    .mint-btn{font-size:18px!important;}
    .why-become-list{
        font-size:small;
    }
    .why-become-hodler{
        padding-top:10px;
        background-color:#5B3C16;
    }
    .whyscreen ul{
        margin-top:50px;
    }
    #roadmap .poop-container{
        background-position:center;
        background-size:cover;
    }
}
@media only screen and (max-width:900px){
    .whyscreen ul{
        margin-top:60px;
    }
    .whyscreen ul > li{
        margin-top: 9px;
    }
    .why-become-list{
        font-size:small;
    }
    .why-become-hodler{
        padding-top:30px;
    }
    #roadmap .poop-container{
        background-size:contain;
    }
}
@media only screen and (max-width:800px){
    .mint-btn{font-size:16px!important;}
    .why-become-list{
        font-size:small;
    }
    .slick-slider{
        margin-top: 80px;
    }
    .whoscreen,#homescreen,#howmanyscreen,.whyscreen{
        padding-bottom:80px;
    }
    .whoscreen p{
        font-size:smaller;
    }
    .navbar-dark .navbar-brand{
        font-size:smaller;
    }
    .whyscreen ul{
        margin-top:60px;
    }
    .whyscreen ul > li{
        margin-top: 9px;
    }
}
@media only screen and (max-width:600px){
    .mint-btn{font-size:12px!important;
        
    }
    
    .why-become-list{
        font-size:smaller;
    }
    .slick-slider{
        margin-top: 50px;
    }
    .whoscreen,#homescreen,#howmanyscreen,.whyscreen{
        padding-bottom:50px;
    }
    .whoscreen p{
        font-size:smaller;
    }
    .whyscreen ul{
        margin-top:100px;
    }
    .whyscreen ul > li{
        margin-top: 6px;
    }
    #roadmap .poop-container{
        text-align:right !important;
    }
    
}
@media only screen and (max-width:400px){
    .mint-btn{font-size:9px!important;}
    .whyscreen ul{
        margin-top:45px;
    }
    .whyscreen ul > li{
        margin-top: 4px;
    }
}
@media only screen and (max-width:200px){
    .mint-btn{font-size:8px!important;}
    .whyscreen ul{
        margin-top:30px;
    }
    .whyscreen ul > li{
        margin-top: 3px;
    }
}